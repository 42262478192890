$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC. 
     */
     
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxCmsContainerHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if ( breakpoint['medium'] ) {
    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');

    // Main slider
    $('.main-slider').slick({
      dots: true,
      arrows:false,
      infinite: true,
      speed: 1200,
      slidesToShow: 1,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 2400,
      pauseOnHover:false,
    });
    // Second slider
    $('.second-slider').slick({
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover:false,
      dots: true,
      arrows:false,
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
    });
    // Machin liste carussel
    $('.carussell').slick({
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
      ]
    });
      // Accorderon - carussell width problem
      $(".machines-table td").each(function() {
        if($.trim($(this).html())=='') {
          $(this).css({
            display: 'none'
          });;
        }
      });
    // Hide empty tables
    $('.accHandler').on('click',function(){
        $('.carussell').slick('setPosition');
    });
    
    // Add active class on .maschinenliste if active page on is third level 
    if ($('.navi li.maschinenliste li').hasClass('active')) {
        $('.navi li.maschinenliste').addClass('active');
    }
    if ($('.navi li.machine-list li').hasClass('active')) {
        $('.navi li.machine-list').addClass('active');
    }
    
    // wrap div around image in
    $( ".add-images > a.lightbox-image" ).wrap( '<div class="col tiny-6 medium-4"></div>' );
    
    // if product hasn't image uploaded
    $(".cms-img-box").each(function() {
        if($.trim($(this).html())=='') {
          $(this).append('<img src="../../../images/nophoto.jpg" alt="" width="320" height="240" class="contentbild lightbox-image" />');
        }
      });

                
});
/////////////// END DOC READY



/**
* PLACE YOUR OWN SCRIPTS HERE
*/
(function(){
  'use strict';

  jQuery(document).ready(function($) {

    // product page like this one: http://www.maschinenfrommer.de/demo/maschinenliste/falzmaschinen.php 
    //show each product description on click

    $('.cms-box').on('click', function(e) {

      e.preventDefault();

      var $this = $(this),
          $cmsContainer = $this.parents('.cms-container'),
          $theRestOfTheCmsContainers = $cmsContainer.siblings('.cms-container'),
          $closeBtn = $cmsContainer.find('.popup-close'),
          $instaShopContainer = $('.instaShopContainer').offset().top,
          $pageNaviHeight = $('.page-navi').height();

          $('html, body').animate({
              scrollTop: $instaShopContainer - $pageNaviHeight - 70
          }, 600);

          $cmsContainer.addClass('showThisCmsBlock');
          $theRestOfTheCmsContainers.addClass('hideTheRestCmsBlocks');

          $closeBtn.on('click', function() {
            $cmsContainer.removeClass('showThisCmsBlock');
            $theRestOfTheCmsContainers.removeClass('hideTheRestCmsBlocks');

            $('html, body').animate({
                scrollTop: $this.offset().top 
            }, 600);

          });// click


    });// click fn

    var maxCmsContainerHeight = -1,
       $allCmsBoxes = $('.cms-box');

     $allCmsBoxes.each(function() {
       maxCmsContainerHeight = maxCmsContainerHeight > $(this).height() ? maxCmsContainerHeight : $(this).height();
     });

     $allCmsBoxes.each(function() {
       $(this).css('min-height', maxCmsContainerHeight);
     });

     // product sliders
     // product page like this one: http://www.maschinenfrommer.de/demo/maschinenliste/falzmaschinen.php 
     $('.add-images-slider').slick({
       infinite: true,
       slidesToShow: 1,
       slidesToScroll: 1,
       autoplay: true,
       arrows: false
     });

     //group lightcase galleries
     // product page like this one: http://www.maschinenfrommer.de/demo/maschinenliste/falzmaschinen.php 
     $('.add-images .lightbox-image img').each(function() {
      var $this = $(this),
          $parentLink = $this.parent(),
          $parentContainer = $this.parents('.cms-product-description');

      $parentLink
        .attr('data-lc-categories', $parentContainer.attr('id'));

     });

     //toggle the submenu
     // product page like this one: http://www.maschinenfrommer.de/demo/maschinenliste/falzmaschinen.php 
     // $('.categories-list .sub').css('display', 'none');
     var cmsNaviTogglerIndicator = $('.categories-list .sub').parent().find(' > a');

     cmsNaviTogglerIndicator.each(function() {

      var $this = $(this),
          $subMenu = $this.next('.sub'),
          $linkParent = $this.parent();

      $this.addClass('cms-navi-toggler');

      $linkParent.on('mouseenter', function() {
        $this.toggleClass('cms-navi-opened');
        $subMenu.stop().slideToggle();
      });

      $linkParent.on('mouseleave', function() {
        $this.toggleClass('cms-navi-opened');
        $subMenu.stop().slideToggle();
      });


     });// each fn

     // add active class to a cms navi
     // product page like this one: http://www.maschinenfrommer.de/demo/maschinenliste/falzmaschinen.php 
     if(!$('body').hasClass('maschinenliste-index')) {
        var pgurl = window.location.href.substr(window.location.href.lastIndexOf("/")+1);

        $(".categories-list li a").each(function(){
             if($(this).attr("href").indexOf(pgurl) !== -1) {
                $(this).parent().addClass("active");
             }
        })// each fn
      
     }
     


  });// ready fn

})();

/**
 * TRIGGER SCROLLED CLASS ON BODY
 */
$(window).on('load scroll resize', function(){
    scrollOffset = $(window).scrollTop();
    console.log(scrollOffset);
    if(scrollOffset > 0) {
        $('body').addClass('scrolled');
    } else {
        $('body').removeClass('scrolled');
    }
});

var myIndex = 0;
carousel();

function carousel() {
    var i;
    var x = document.getElementsByClassName("mySlides");
    for (i = 0; i < x.length; i++) {
       x[i].style.display = "none";  
    }
    myIndex++;
    if (myIndex > x.length) {myIndex = 1}    
    x[myIndex-1].style.display = "block";  
    setTimeout(carousel, 2000); // Change image every 2 seconds
}

$(document).ready(function()
{
   $('#map').click(function(){
    $(this).addClass("active");
   });
   $('#map').mouseleave(function(){
    $(this).removeClass("active");
   });
   var width = $(window).width();
    if (width > 751 && 
    $('.slides img').length > 1) {
     $('.flexslider').flexslider({
      animation: "fade",
      controlNav: false,
      directionNav: false,
      useCSS : false,
      });
  }
});